import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The following table lists the fields of Visitors data source that you can use in rules, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Category > Sub-category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser that the visitor is using.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser resolution`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cookie`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ID of the cookie of the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coremedia VID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Country`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Location information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country of the visitor or contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device family`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device model`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device operating system`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device vendor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fid`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`First contact date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`First visit date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Identification type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Internet provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last contact date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last visit date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mood`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General NPS information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mood detected and recorded for the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NPS value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General NPS information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Organization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Source or link that referred the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Region`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Location information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remote IP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Search expression`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segment IDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitors information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TBD`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      